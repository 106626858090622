<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
	<div>
		<h1 class="mt-4">Fuzzy Search / Filtering Demo</h1>

		<p>
			Try these search keywords to see search results: <br />
			<code>
				430, x-ray, xray, x ray, botox, Botox, boatox, bowtox, bausch and Lomb, behind ear
			</code>
		</p>
		<p>
			French samples: <br />
			<code> é (alt+130), â (alt+131), É (alt+144), ô (alt+147) </code>
		</p>

		<b-input-group class="mt-3">
			<b-form-input
				v-model="searchKey"
				class="search-input"
				placeholder="Search by keyword"
				type="search"
				maxlength="40"
				@keyup="runSearch"
				@update="resetSearch"
			></b-form-input>
			<template #append>
				<b-button class="search-button" variant="primary">
					<FontAwesomeIcon class="mr-2" :icon="['fal', 'search']"></FontAwesomeIcon>
					<span>Search</span>
				</b-button>
			</template>
		</b-input-group>
		<p>(To clear search input field, click "x" in field or press "esc" while still in field)</p>

		<h2 class="h4 mt-4">Benefits</h2>
		<hr />
		<table>
			<tr v-for="benefit in searchResults" :key="benefit.provider">
				<th>{{ benefit.provider }}</th>
				<th>{{ benefit.desc.name }}</th>
				<th>{{ benefit.desc.id }}</th>
			</tr>
		</table>
	</div>
</template>

<script>
import { BInputGroup, BFormInput, BButton } from 'bootstrap-vue';

export default {
	name: 'FuzzySearchDemoPage',
	components: {
		BInputGroup,
		BFormInput,
		BButton
	},
	data() {
		return {
			searchKey: '',
			searchResults: [],
			benefits: [
				{
					provider: 'Abbott',
					desc: {
						name: 'Complete hearing assessment',
						id: '1345'
					}
				},
				{
					provider: 'Bausch & Lomb ',
					desc: {
						name: 'Cornea transplant',
						id: '5430'
					}
				},
				{
					provider: 'GlaxoSmithKline ',
					desc: {
						name: 'Behind-the-Ear hearing aid, left: TICAGRÉLOR(tm)',
						id: '0543'
					}
				},
				{
					provider: 'Sigma Healthcare',
					desc: {
						name: 'X-ray',
						id: '1143'
					}
				},
				{
					provider: 'Zentiva ',
					desc: {
						name: 'Portable concentrator +2 batteries, purchase',
						id: '0432'
					}
				},
				{
					provider: 'Zétivô France',
					desc: {
						name: 'Électrolytes-diurétiques, coût max 5%, personne âgée de moins de 18 ans',
						id: '0436'
					}
				},
				{
					provider: 'Johnson & Johnson',
					desc: {
						name: 'Botox 150 mg/mL',
						id: '2232'
					}
				}
			],
			fuseOptions: {
				isCaseSensitive: false,
				shouldSort: true, // Sort to show the most relevant results on top.
				minMatchCharLength: 3, // Match at least 3 characters.
				location: 0, // Start searching at beginning of string. (default: 0)
				distance: 300, // How far into the string to search.
				threshold: 0.3, // Lowering this requires member to be more precise in their typing. (default: 0.6)
				keys: ['provider', 'desc.name', 'desc.id']
			}
		};
	},
	created() {
		// Initialized with all data
		this.searchResults = this.benefits;
	},

	methods: {
		runSearch() {
			// We require at least 3 characters for searches
			if (this.searchKey.length < 3) {
				return;
			}
			this.$search(this.searchKey, this.benefits, this.fuseOptions).then((result) => {
				this.searchResults = result;
			});
		},
		resetSearch() {
			// When clicked "x" in search input to clear search parameter (because of type="search") we re-init
			// with all data
			if (this.searchKey === '') {
				this.searchResults = this.benefits;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.search-input {
	background-color: $white;
}

.search-button {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top: 0;
	border-bottom: 0;
	line-height: 1;
}

table {
	width: 100%;
	font-size: 15px;
}

th {
	padding: 6px;
}
</style>
